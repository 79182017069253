import { makeStyles } from '@material-ui/styles';
import { ITheme } from '../../../common/theme';

export default makeStyles((theme: ITheme) => ({
  card: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: 5,
  },
  cardHeader: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  avatar: {
    marginRight: theme.spacing(3.5),
  },
  cardContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
}));
