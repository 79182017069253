import Box from '../Box';
import Typography from '../Typography';
import { LinkButton } from '@/components/Internal/Button';
import Avatar from '../Avatar';
import { LetsConnectCardProps } from './LetsConnectCardDesktop';
import { ArrowRightV2 } from '../Icons';

const LetsConnectCardDesktop: React.FC<Omit<LetsConnectCardProps, 'title'>> = ({
  personName,
  personPosition,
  avatarSrc,
  buttonText,
  ctaLink,
}) => {
  return (
    <Box
      width="fit-content"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <LinkButton
        href={ctaLink}
        variant="filled"
        radius="circular"
        size="small"
        endIcon={<ArrowRightV2 />}
        aria-label={personName}
        id="cta_main_link"
      >
        {buttonText}
      </LinkButton>
      <Box mt={2} display="flex" alignItems="center">
        <Avatar src={`${avatarSrc}`} size="xs" alt={personName} />
        <Box ml={3}>
          <Box mr={1} display="inline">
            <Typography display="inline" variant="body2" color="common.white">
              {personName}
            </Typography>
          </Box>
          <Typography
            display="inline"
            variant="body2"
            fontWeight={400}
            color="common.white"
          >
            {`(${personPosition})`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LetsConnectCardDesktop;
