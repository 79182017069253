import { Content, isFilled } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';

import { useInView } from 'react-intersection-observer';
import Link from 'next/link';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useWindowSize, useOrientation } from 'react-use';
import Zoom from '@material-ui/core/Zoom';
import Box from '@/components/Unknown/Box';
import Container from '@/components/Unknown/Container';
import Typography from '@/components/Unknown/Typography';
import IconButton from '@/components/Unknown/IconButton';
import { PrismicNextLink } from '@/components/Internal/Button';
import LetsConnectCard from '@/components/Unknown/LetsConnectCard';
import {
  ChatIcon,
  ArrowDownSecondaryIcon,
  ArrowRightV2,
} from '@/components/Unknown/Icons';
import { PrismicNextImage } from '@prismicio/next';
import useStyles from './useStyles';

/**
 * Props for `Hero`.
 */
export type HeroProps = SliceComponentProps<Content.HeroSlice>;

/**
 * Component for "Hero" Slices.
 */
const Hero = ({ slice }: HeroProps): JSX.Element => {
  const classes = useStyles();
  const orientation = useOrientation();
  const [letsConnectCardRef, letsConnectCardInView] = useInView();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { height: windowHeight } = useWindowSize();

  let mobileHeight = 550;
  const desktopHeight = 660;
  const titleVariant = isTablet && !isMobile ? 'h2' : 'h1';

  switch (slice.variation) {
    case 'centered':
      mobileHeight = 620;
      return (
        <section className="relative grid [&>*]:col-start-1 [&>*]:row-start-1">
          <PrismicNextImage
            field={slice.primary.background_image}
            fallbackAlt=""
            priority
            sizes="100vw"
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <article
            className="place-content-center"
            style={{ backgroundColor: 'rgba(23, 35, 45, 0.75)' }}
          >
            <div className="mx-auto max-w-[1290px] px-[25px] ~pt-24/32 ~pb-28/36">
              <div className="~md/lg:px-0/20 flex w-full flex-col items-center px-0 text-center ~lg/desktop:~gap-4/6">
                <PrismicRichText
                  field={slice.primary.page_name}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="font-normal uppercase tracking-[4.8px] text-white ~md:~text-arc-xs/arc-base">
                        {children}
                      </p>
                    ),
                  }}
                />
                <h1 className="max-w-[1070px] font-bold text-white ~mobile:~text-arc-7xl/arc-12xl">
                  {slice.primary.title}
                  <span className="text-latte">
                    {` ${slice.primary.secondary_title}`}
                  </span>
                </h1>
                <h2 className="x-auto max-w-[870px] text-pretty text-center text-white ~md:~text-arc-xl/arc-2xl">
                  {slice.primary.subtitle}
                </h2>
                <PrismicNextLink
                  field={slice.primary.button_link}
                  endIcon={<ArrowRightV2 />}
                  className="mt-4 lg:mt-0"
                  radius={isTablet ? 'circular' : 'rounded'}
                >
                  {slice.primary.button_label}
                </PrismicNextLink>
              </div>
            </div>
          </article>
        </section>
      );
    default:
      const isMobileLandscapeOrientation =
        windowHeight < theme.breakpoints.values.md && orientation.angle === 90;
      const mediaType =
        !isMobile && !isMobileLandscapeOrientation ? 'video' : 'image';
      const isLinkedMediaVideo = (url: string) => {
        return url.endsWith('.mp4');
      };
      const backgroundVideo =
        isFilled.linkToMedia(slice.primary.video_link) &&
        isLinkedMediaVideo(slice.primary.video_link.url)
          ? slice.primary.video_link.url
          : 'https://corporate-website.cdn.prismic.io/corporate-website/ZliOnaWtHYXtT8Bq_home-hero-video.mp4';

      const showChatIcon =
        !letsConnectCardInView && slice.primary.show_chat_button;

      return (
        <Box
          position="relative"
          minHeight={{ xs: mobileHeight, md: desktopHeight }}
          className={classes.section}
        >
          <Box display="flex" justifyContent="center">
            {mediaType === 'video' && (
              <video
                preload="none"
                className={classes.mediaVideo}
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={backgroundVideo} type="video/mp4" />
              </video>
            )}
            {mediaType === 'image' && (
              <PrismicNextImage
                field={slice.primary.image_fallback}
                fallbackAlt=""
                priority
                fill
                sizes="100vw"
                className="object-cover"
              />
            )}
          </Box>
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            display="flex"
            alignItems="center"
            className={classes.mask}
          >
            <Container classes={{ root: classes.container }}>
              <Box
                height={1}
                pb={6}
                pt={{ xs: 37, md: 40 }}
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'center', md: 'flex-start' }}
                justifyContent="space-between"
              >
                <Box
                  flexShrink={{ md: 1 }}
                  textAlign={{ xs: 'center', md: 'left' }}
                  paddingRight={{ xs: 0, md: 0, lg: 15 }}
                >
                  <Typography
                    display="inline"
                    variant={titleVariant}
                    component="h1"
                    color="common.white"
                  >
                    {slice.primary.title}
                    <Typography
                      display="inline"
                      variant={titleVariant}
                      component="span"
                      color="secondary.main"
                    >
                      {` ${slice.primary.secondary_title}`}
                    </Typography>
                  </Typography>
                  <h2 className="mt-5 text-white ~sm/md:~text-arc-xl/arc-2xl md:max-w-[638px]">
                    {slice.primary.subtitle}
                  </h2>
                </Box>
                <Box ml={{ md: 4 }} flexShrink={0} ref={letsConnectCardRef}>
                  {!isMobile && (
                    <Box className="hidden md:block" maxWidth={325}>
                      {<LetsConnectCard />}
                    </Box>
                  )}
                  {isMobile && slice.primary.show_card_on_mobile && (
                    <LetsConnectCard />
                  )}
                  {isMobile && !slice.primary.show_card_on_mobile && (
                    <Box pb={9}>
                      <Link href="#nav">
                        <ArrowDownSecondaryIcon />
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>

          <Box
            position="fixed"
            bottom="10%"
            right="10%"
            zIndex={showChatIcon ? 5 : -1}
          >
            <Zoom in={showChatIcon}>
              <IconButton
                href="/enquiry"
                aria-label="Enquiry"
                classes={{ root: classes.chatIconButton }}
                color="primary"
                size="xl"
                elevated
              >
                <ChatIcon />
              </IconButton>
            </Zoom>
          </Box>
        </Box>
      );
  }
};

export default Hero;
