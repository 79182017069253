import { forwardRef } from 'react';
import IconButtonBase, {
  IconButtonProps as IconButtonBaseProps,
} from '@material-ui/core/IconButton';
import useStyles from './useStyles';

interface IconButtonProps extends Omit<IconButtonBaseProps, 'size'> {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  elevated?: boolean;
  href?: string;
  target?: string;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ size = 'm', children, elevated, ...props }, ref) => {
    const classes = useStyles();

    return (
      <IconButtonBase
        ref={ref}
        className={`${classes[size]} ${elevated && classes.elevated}`}
        {...props}
      >
        {children}
      </IconButtonBase>
    );
  },
);

export default IconButton;
