import { makeStyles } from '@material-ui/styles';
import { ITheme } from '../../../common/theme';

export default makeStyles((theme: ITheme) => ({
  xs: {
    width: 23,
    height: 23,
    fontSize: 12,
  },
  s: {
    width: 35,
    height: 35,
    fontSize: 18,
  },
  m: {
    width: 40,
    height: 40,
    fontSize: 14,
  },
  l: {
    width: 45,
    height: 45,
    fontSize: 14,
  },
  xl: {
    width: 50,
    height: 50,
    fontSize: 14,
  },
  xxl: {
    width: 56,
    height: 56,
    fontSize: 14,
  },
  elevated: {
    boxShadow: theme.shadows[4],
  },
}));
