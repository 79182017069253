import { useMediaQuery, useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import LetsConnectCardDesktop, {
  LetsConnectCardProps,
} from './LetsConnectCardDesktop';
import LetsConnectCardMobile from './LetsConnectCardMobile';
import LetsConnectAvatar from './lets-connect-avatar.webp';
import messages from './messages';

const LetsConnectCard: React.FC<Partial<LetsConnectCardProps>> = ({
  avatarSrc = LetsConnectAvatar.src,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const letsConnectCardArgs = {
    title: intl.formatMessage(messages.title),
    personName: intl.formatMessage(messages.personName),
    personPosition: intl.formatMessage(messages.personPosition),
    avatarSrc,
    buttonText: intl.formatMessage(messages.buttonText),
    ctaLink: '/enquiry',
  };

  if (isMobile) {
    return <LetsConnectCardMobile {...letsConnectCardArgs} />;
  }
  return <LetsConnectCardDesktop {...letsConnectCardArgs} />;
};

export default LetsConnectCard;
