import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Unknown.LetsConnectCard.title',
    defaultMessage: 'Need a better tech hire option?',
  },
  personName: {
    id: 'Unknown.LetsConnectCard.personName',
    defaultMessage: 'Fred Joye',
  },
  personPosition: {
    id: 'Unknown.LetsConnectCard.personPosition',
    defaultMessage: 'Co-founder',
  },
  buttonText: {
    id: 'Unknown.LetsConnectCard.buttonText',
    defaultMessage: "Let's connect",
  },
});
