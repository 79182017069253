import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  xs: {
    width: 32,
    height: 32,
  },
  s: {
    width: 55,
    height: 55,
  },
  m: {
    width: 70,
    height: 70,
  },
  l: {
    width: 85,
    height: 85,
  },
  xl: {
    width: 120,
    height: 120,
  },
  xxl: {
    width: 190,
    height: 190,
  },
});
