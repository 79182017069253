import AvatarBase, {
  AvatarProps as AvatarBaseProps,
} from '@material-ui/core/Avatar';
import useStyles from './useStyles';

interface AvatarProps extends AvatarBaseProps {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}

const Avatar: React.FC<AvatarProps> = ({ size = 'm', children, ...props }) => {
  const classes = useStyles();

  return (
    <AvatarBase className={classes[size]} {...props}>
      {children}
    </AvatarBase>
  );
};

export default Avatar;
