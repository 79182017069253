import Card from '../Card';
import Box from '../Box';
import Typography from '../Typography';
import CardHeader from '../CardHeader';
import CardContent from '../CardContent';
import { LinkButton } from '@/components/Internal/Button';
import Avatar from '../Avatar';
import useStyles from './useStyles';
import { ArrowRightV2 } from '../Icons';
import { StaticImageData } from 'next/image';

export interface LetsConnectCardProps {
  title: string;
  personName: string;
  personPosition: string;
  avatarSrc: StaticImageData | string;
  buttonText: string;
  ctaLink: string;
}

const LetsConnectCardDesktop: React.FC<LetsConnectCardProps> = ({
  title,
  personName,
  personPosition,
  avatarSrc,
  buttonText,
  ctaLink,
}) => {
  const classes = useStyles();

  return (
    <Card elevation={0} classes={{ root: classes.card }}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          avatar: classes.avatar,
        }}
        title={
          <Typography
            fontSize={22}
            lineHeight={1.2}
            fontWeight={700}
            color="primary"
          >
            {title}
          </Typography>
        }
        subheader={
          <Box mt={3}>
            <Box display="inline" mr={1}>
              <Typography display="inline" variant="body1">
                {personName}
              </Typography>
            </Box>
            <Typography display="inline" variant="body1">
              {`(${personPosition})`}
            </Typography>
          </Box>
        }
        avatar={<Avatar src={`${avatarSrc}`} size="l" alt={personName} />}
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <LinkButton
          href={ctaLink}
          endIcon={<ArrowRightV2 />}
          id="cta_main_link"
          aria-label={personName}
        >
          {buttonText}
        </LinkButton>
      </CardContent>
    </Card>
  );
};

export default LetsConnectCardDesktop;
