import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';
import { ITheme } from '@/common/theme';

export default makeStyles((theme: ITheme) => ({
  container: {
    height: '100%',
  },
  section: {
    overflow: 'hidden',
  },
  mask: {
    backgroundColor: alpha(theme.palette.background.secondaryDark, 0.75),
  },
  media: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  mediaVideo: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  chatIconButton: {
    width: 66,
    height: 66,
    fontSize: 33,
  },
  subtitle: {
    fontWeight: 400,
  },
  homeSlogan: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '26px',
    [theme.breakpoints.up('md')]: {
      fontSize: 23,
      fontWeight: 400,
      lineHeight: '30px',
    },
  },
}));
